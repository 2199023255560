<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <div class="row">
            <div class="col-md-12 d-flex align-items-stretch grid-margin">
                <div class="row flex-grow">
                    <div class="col-12 grid-margin">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title" v-if="form.id == ''">Add New Role</h4>
                                <h4 class="card-title" v-else>Update Role</h4>
                            </div>
                            <div class="card-body new-card-body">
                                <form class="forms-sample" @submit.stop.prevent="onSubmit">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <b-form-group label="Name*" label-for="name">
                                                <b-form-input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        placeholder="Name"
                                                        v-model="$v.form.name.$model"
                                                        :state="$v.form.name.$dirty ? !$v.form.name.$error : null"
                                                        aria-describedby="input-1-live-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback id="input-1-live-feedback">This is a required
                                                    field.
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </div>
                                        <div class="col-lg-6 col-md-6">

                                            <b-form-group label="Permissions" label-for="permissions">
                                                <v-select multiple v-model="form.permissions"
                                                          :options="permissionsData">
                                                    <!--                                            <v-btn @click="selectAllPermissions($event)">Select all</v-btn>-->
                                                </v-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <b-button type="submit" :disabled="isDisabled" variant="success" class="mr-2 mt-2">
                                        <span v-if="form.id == ''">Submit</span>
                                        <span v-else>Update</span>
                                    </b-button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <!--            <vue-snotify></vue-snotify>-->
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Roles</h4>
                    </div>
                    <div class="card-body new-card-body">
                        <vue-good-table
                                mode="remote"
                                @on-page-change="onPageChange"
                                @on-sort-change="onSortChange"
                                @on-column-filter="onColumnFilter"
                                @on-per-page-change="onPerPageChange"
                                :totalRows="totalRecords"
                                :isLoading.sync="isLoading"
                                @on-search="searchFn"
                                :search-options="{enabled: true,searchFn: searchFn}"
                                :pagination-options="{
    enabled: true,
      dropdownAllowAll: false,
    perPageDropdown: [10, 20, 50, 100, 200],
  }"
                                :rows="rows"
                                :columns="columns">
                            <template slot="table-row" slot-scope="props">
                                                             <span
                                                                     v-if="props.column.field === 'action'"
                                                                     class="text-nowrap"
                                                             >
                                                                 <b-button size="sm" @click="editRole(props.row.id)"
                                                                           class="mr-1 btn-info text-white">
                                                                Edit
                                                            </b-button>
                                                             </span>
                            </template>
                        </vue-good-table>

                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </section>
</template>

<script>
    import Vue from "vue";
    import SortedTablePlugin from "vue-sorted-table";
    import API from '@/api'
    import {validationMixin} from "vuelidate";
    import {required, minLength, email, sameAs} from "vuelidate/lib/validators";
    import Snotify, {SnotifyPosition} from 'vue-snotify'

    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    Vue.use(SortedTablePlugin, {
        ascIcon: '<i class="ti-arrow-down"></i>',
        descIcon: '<i class="ti-arrow-up"></i>'
    });
    export default {
        name: 'Clients',
        mixins: [validationMixin],
        data() {
            return {
                form: {
                    name: "",
                    id: "",
                    permissions: []
                },
                roles: [],
                allSelected: false,
                permissionsData: [],
                numberOfRoles: "",
                isDisabled: false,
                serverParams: {
                    // a map of column filters example: {name: 'john', age: '20'}
                    columnFilters: {},
                    sort: [
                        {
                            field: 'id', // example: 'name'
                            type: 'desc' // 'asc' or 'desc'
                        }
                    ],

                    page: 1, // what page I want to show
                    perPage: 10 // how many items I'm showing per page
                },
                columns: [
                    {
                        label: 'Name',
                        field: 'name',
                        filterable: true
                    },
                    {
                        label: 'Action',
                        field: "action",
                        sortable: false,
                    },
                ],
                isLoading: false,
                rows: [],
                totalRecords: 0,
            };
        }, validations: {
            form: {
                name: {
                    required,
                },
            }
        },
        methods: {
            selectAllPermissions() {

                if (this.allSelected === false) {

                    this.permissionsData.forEach(permission => {
                        this.form.permissions.push(permission.name);
                    });
                } else {
                    this.form.permissions = [];
                }


            },
            loadItems() {
                API.getroles(this.serverParams,
                    data => {
                        this.totalRecords = data.totalRecords;
                        this.rows = data.data.data;

                    },
                    err => {
                    }
                )
            },
            getAllPermissions() {
                API.getAllPermissions(
                    data => {
                        data.forEach((value, index) => {
                            this.permissionsData.push(value.name);
                        });
                        console.log(this.permissionsData)
                    },
                    err => {
                    }
                )
            },

            editRole(e) {
                this.form.permissions = [];
                window.scrollTo({top: 0, behavior: 'smooth'});
                API.get_edit_roles(
                    e, data => {
                        this.form.name = data.name;
                        this.form.id = data.id;
                        let permissions_edit = data.permissions;
                        permissions_edit.forEach(core => {
                            this.form.permissions.push(core.name);
                        });
                    },
                    err => {
                    }
                )
            },
            onSubmit() {
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }
                this.isDisabled = true;
                if (this.form.name != '') {
                    API.add_edit_roles(
                        this.form,
                        data => {
                            this.$snotify.success(data, {
                                timeout: 2000,
                                showProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            this.form.name = '';
                            this.form.id = '';
                            this.allSelected = false;
                            this.form.permissions = [];
                            this.$v.form.$reset();
                            this.loadItems();
                            this.isDisabled = false;
                        },
                        err => {
                            this.isDisabled = false;
                        }
                    )
                } else {
                    this.$snotify.warning('Please fill required fields', {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    this.isDisabled = false;
                }
            }, resetForm() {
                var self = this; //you need this because *this* will refer to Object.keys below`

                //Iterate through each object field, key is name of the object field`
                Object.keys(this.data.form).forEach(function (key, index) {
                    self.data.form[key] = '';
                });
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                console.log(params.sortType);
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },
            searchFn(params) {
                this.updateParams({searchTerm: params});
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            }
        }, computed: {},
        beforeMount() {
            this.getAllPermissions();
            this.loadItems();
        },
        mounted() {
        }
    }
</script>

<style>
    .row .card .new-card-body {
        padding: 1.25rem 1.437rem !important;
    }
    @media (max-width: 450px) {
        .vgt-wrap__footer .footer__navigation__page-btn:nth-child(3) {
            float: right;
            margin-left: 8px;
        }
        .vgt-wrap__footer .footer__navigation__page-btn:nth-child(2) {
            margin-left: 0px;
            float: left;
        }
        .vgt-wrap__footer .footer__navigation__page-info {
            width: 100%;
            text-align: center;
            margin: 0;
        }
        .vgt-pull-right {
            float: inherit !important;
        }
    }
    @media (max-width: 375px) {
        .row .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
</style>